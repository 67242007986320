<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #FF0000">แก้ไขประเภทสินค้า</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="mt-5"
            ><span>สถานะ</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"><span>ชื่อประเภทสินค้า</span> </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ typeName }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"><span>รายละเอียด</span> </v-col>
          <v-col cols="12" md="7" sm="7">
              <span>{{ typeDescription }}</span>
          </v-col>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <!-- <v-btn @click="submit()" color="primary">บันทึก</v-btn> -->
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      typeName: "",
      typeDescription: "",
      activeFlag: true,
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    var Typedata = JSON.parse(Decode.decode(localStorage.getItem("Typedata")));
    console.log("Typedata", Typedata);
    this.id = Typedata.id;
    this.typeName = Typedata.type;
    this.typeDescription = Typedata.description;
    this.activeFlag = Typedata.activeFlag;
  },
  methods: {
    cancel() {
      this.$router.push("ManageType");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E` },
        };
        const data = {
          id: this.id,
          type: this.typeName,
          description: this.typeDescription,
          status: this.typeStatus,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/types/` + this.id,
          data,
          auth
        );
        console.log("createType", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างประเภทสินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageType");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างประเภทสินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>